import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance from "../../Services";

export const GetPersonalRecords = createAsyncThunk("GetPersonalRecords", async(_, {rejectWithValue}) => {
    const url = `/api-profile/records`
    const response = await instance.get(url)
    try{
        const result = response.data
        console.log(result)
        if(result.data.not_achieved){
            var not_achieved = result.data.not_achieved
            not_achieved.run.map((val,index) => {
               result.data.run.push(val)
            })
            not_achieved.ride.map((val,index) => {
                result.data.ride.push(val)
             })
        }
        return result
    }catch(e){
        return rejectWithValue(e)
    }
})

const DashboardSlice = createSlice({
    name: "DashboardSlice",
    initialState : {
        personalRecords : [],
        loading: false,
    },
    extraReducers : (builder) => {
        builder.addCase(GetPersonalRecords.pending, (state, action) =>{
            state.loading = true
        }).addCase(GetPersonalRecords.fulfilled, (state, action) => {
            state.loading = false
            state.personalRecords = action.payload
        }).addCase(GetPersonalRecords.rejected, (state, action) =>{
            state.loading = false
        })
    }
})

export default DashboardSlice.reducer