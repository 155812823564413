import { Routes, Route, Link,useNavigate,useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {useLocation} from 'react-router-dom'
import { useState,useEffect,useContext } from "react";
import $ from 'jquery';
//context
import { ThemeContext, themes } from '../Context/Context';
import { UserContext } from '../Context/Context';

import Swal from 'sweetalert2'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';
import qs from 'qs';
import { hotjar } from 'react-hotjar';
import CustomButtonBasic from "../Components/CustomButtonBasic";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { LoadingSpinner } from "video-react";
import SpinnerLoader from "../Components/SpinnerLoader";
import ReactImageFallback from "react-image-fallback";

import { useSelector, useDispatch } from 'react-redux'
import { setData } from '../Redux/Slice/user'


//service
import logout from "../Services/Function/Auth/logout";


import notify from "../Services/Utility/toast";
import useLocalStorage from "../Services/Hooks/useLocalStorage";
import exchange from "../Services/Function/Auth/exchange";

const Header = (props) =>{
  // const {getUser,logoutContext,loginContext} = useContext(UserContext)
  const [loading,setLoading] = useState(false)
  const [profile,setProfile] = useState(null)
  const [profileMenu,setProfileMenu] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();

  const user = useSelector((state) => state.user.value)
  const dispatch = useDispatch()
  // const [isLoggedIn,setIsLoggedIn] = useState((user.token != undefined))
  const isLoggedIn = (user.token != undefined)

  useEffect(() => {
    hotjar.initialize(process.env.REACT_APP_HOTJAR_HJID, process.env.REACT_APP_HOTJAR_HJSV);

    //getProfileSimple
    if(!isLoggedIn){
    }
  }, [])
  
    var location = useLocation();
    const [darkmodeActive, setDarkmodeActive] = useLocalStorage("darkmode", false);
    var name = (location === undefined ? '/' : location.pathname)
    let navigate = useNavigate();

    const notify = (message) => {
        toast(message, {
        position: toast.POSITION.TOP_CENTER,
        className:'bg-red-500 font-semibold text-center text-sm py-0 text-white',
        });
    };

    function logOutOpen(){
        var bg = darkmodeActive ? '#1A1A1A': 'white'
        Swal.fire({
            title: "Are you sure?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            showCancelButton: true,
            confirmButtonText: 'Log out',
            confirmButtonColor:'#B3282C',
            background: bg,
        }).then((result) => {
            if (result.isConfirmed) {
              notify('Please Wait')
              logout()
              .finally(res=>{
                dispatch(setData({}))
                navigate('/')
                notify("Good Bye")
              })
            }
          })

    }

    return(
        <>
            <div id="overlay" className="overlay w-screen h-screen absolute top-0 left-0 bg-gray-900 opacity-[0.2] hidden" style={{zIndex:100}} onClick={hideSidebar}>

            </div>
            <div className="absolute top-0 left-0 flex flex-col justify-center h-full z-2 w-screen" style={{zIndex:101}}>
                <div className="flex justify-between h-auto w-full px-6">
                  <div className="flex menu">
                    <div className="flex items-center mr-3">
                      <Link to='/'>
                          <div className="logo">
                          </div>
                      </Link>
                    </div>                  
                    <div className="items-center mx-3 hidden md:flex">
                      <Link to='/aboutus' className={`${name=='/aboutus'? 'active' : ''} flex cursor-pointer`}>
                          <div className="font-bold">About</div>
                      </Link>
                    </div>
                    <div className="items-center mx-3 hidden md:flex">
                      <Link to='/race' className={`${name=='/race'? 'active' : ''} flex cursor-pointer`}>
                          <div className="font-bold">Events</div>
                      </Link>
                    </div>
                    <div className="items-center mx-3 hidden md:flex">
                      <Link to='/challenges' className={`${name=='/challenges'? 'active' : ''} flex cursor-pointer`}>
                          <div className="font-bold">Challenges</div>
                      </Link>
                    </div>
                    <div className="items-center mx-3 hidden md:flex">
                      <Link to='/contactus' className={`${name=='/contactus'? 'active' : ''} flex cursor-pointer`}>
                          <div className="font-bold">Contact Us</div>
                      </Link>
                    </div>

                  </div>
                  <div className="flex items-center">
                    {
                      !isLoggedIn && props.showLogin ? 
                      <div className="hidden md:block">
                        <Link className="border rounded px-4 py-1 font-semibold border-red-800 mr-2 hover:text-white hover:bg-red-800" to={'/login'}>Log In</Link>
                      </div>:<></>
                    }
                    {
                      isLoggedIn ? 
                        (
                          <div className="relative hidden md:block">
                            <div onClick={()=>{setProfileMenu(!profileMenu)}} onMouseEnter={()=>{setProfileMenu(true)}} className="flex items-center font-semibold cursor-pointer relative">
                              <ReactImageFallback
                                src={`${user.user.user_profilepicture == null ? '' : user.user.user_profilepicture.replace("cause.id/img","img.cause.id")}`}           
                                fallbackImage={'https://img.cause.id/dashboard_man.png'}
                                initialImage={`${user.user.user_profilepicture == null ? '' : user.user.user_profilepicture.replace("cause.id/img","img.cause.id")}`}           
                                className="w-[40px] h-[40px] bg-gray-400 mr-2 bg-center bg-cover rounded-full"
                              >
                                
                              </ReactImageFallback>
                             
                              <div className=" mr-2" >{user.user.user_firstname.concat(" ",user.user.user_lastname).length <=14 ? user.user.user_firstname.concat(" ",user.user.user_lastname) : user.user.user_firstname.concat(" ",user.user.user_lastname).substring(0,14)+'...'}</div>
                              <div className=" mr-3"><FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon></div>
                              
                            </div>
                            {profileMenu ? 
                             <div onMouseLeave={()=>{setProfileMenu(false)}} className="mt-[25px] cursor-pointer absolute top-[30px] bg-white text-md shadow-md right-3 flex flex-col font-normal">
                              <Link to={'/dashboard'}><div className="py-2 px-6 hover:bg-red-700 hover:text-white">Profile</div></Link>
                              <Link to='/settings#ConnectApps'><div className="py-2 px-6 hover:bg-red-700 hover:text-white">Connect Apps</div></Link>
                              <Link to='/settings#EditProfile'><div className="py-2 px-6 hover:bg-red-700 hover:text-white">Settings</div></Link>
                              <div className="py-2 px-6 hover:bg-red-700 hover:text-white" onClick={logOutOpen}>Log Out</div>
                            </div>:<></>
                            }
                           
                          </div>
                        )
                      :<></>
                    }
                    
                    <div 
                    className="flex flex-col justify-center cursor-pointer pr-3"
                    onClick={showSidebar}
                    >
                        <FontAwesomeIcon className="align-middle" icon="bars" />
                    </div>

                  </div>
                    
                </div>
            </div>

            <div id="sidebar" className="sidebar absolute sidebar-hidden shadow-xl">
                <div className="flex flex-col justify-between h-full w-full pl-3">
                    <div>
                        <div className="flex justify-between md:justify-end pl-4 pr-5 pt-5 pb-12">
                          {isLoggedIn ? 
                            <>
                            <div className="relative block md:hidden">
                                <div onClick={()=>{setProfileMenu(!profileMenu)}} onMouseEnter={()=>{setProfileMenu(true)}} className="flex items-center font-semibold cursor-pointer relative">
                                  <ReactImageFallback
                                    src={`${user.user.user_profilepicture == null ? '' : user.user.user_profilepicture.replace("cause.id/img","img.cause.id")}`}           
                                    fallbackImage={'https://img.cause.id/dashboard_man.png'}
                                    initialImage={`${user.user.user_profilepicture == null ? '' : user.user.user_profilepicture.replace("cause.id/img","img.cause.id")}`}           
                                    className="w-[40px] h-[40px] bg-gray-400 mr-2 bg-center bg-cover rounded-full"
                                  >
                                    
                                  </ReactImageFallback>
                                 
                                  <div className=" mr-2" >{user.user.user_firstname.concat(" ",user.user.user_lastname).length <=14 ? user.user.user_firstname.concat(" ",user.user.user_lastname) : user.user.user_firstname.concat(" ",user.user.user_lastname).substring(0,14)+'...'}</div>
                                  <div className=" mr-3"><FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon></div>
                                  
                                </div>
                                {profileMenu ? 
                                <div onMouseLeave={()=>{setProfileMenu(false)}} className="cursor-pointer absolute top-[40px] bg-white text-md shadow-md right-3 flex flex-col font-normal" style={{zIndex:2}}>
                                  <Link onClick={hideSidebar} to={'/dashboard'}><div className="py-3 px-3 hover:bg-red-700 hover:text-white">Profile</div></Link>
                                  <Link onClick={hideSidebar} to='/settings#ConnectApps'><div className="px-3 py-3 hover:bg-red-700 hover:text-white">Connect Apps</div></Link>
                                  <Link onClick={hideSidebar} to='/settings#EditProfile'><div className="px-3 py-3 hover:bg-red-700 hover:text-white">Settings</div></Link>
                                  <div className="py-3 px-3 hover:bg-red-700 hover:text-white" onClick={logOutOpen}>Log Out</div>
                                </div>:<></>
                                }
                                  
                            </div>   
                            </>
                            
                          :
                            props.showLogin ?
                            <Link onClick={hideSidebar} className="block md:hidden border rounded px-4 py-1 font-semibold border-red-800 mr-2 hover:text-white hover:bg-red-800" to={'/login'}>Log In</Link>       
                            :<></>                   
                          }
                          <div className="flex items-center">
                            <div className="">
                            <FontAwesomeIcon 
                            className="cursor-pointer color-primary"
                                icon="times" 
                                size="lg"
                                onClick={hideSidebar}/>
                            </div>
                          </div>
                            
                        </div>
                        <div className="menu">
                            <Link onClick={hideSidebar} to='/' className={`${name=='/'? 'active' : ''} flex md:hidden cursor-pointer my-4`}>
                                <div className={`navbar-icon icon-home mx-3`}></div>
                                <div className="font-bold">Home</div>
                            </Link>
                            <Link onClick={hideSidebar} to='/race' className={`${name=='/race'? 'active' : ''} flex md:hidden cursor-pointer my-4`}>
                                <div className="navbar-icon icon-event mx-3"></div>
                                <div className="font-bold">Events</div>
                            </Link>
                            <Link onClick={hideSidebar} to='/challenges' className={`${name=='/challenges'? 'active' : ''} flex cursor-pointer my-4`}>
                                <div className="navbar-icon icon-challenge mx-3"></div>
                                <div className="font-bold">Challenges</div>
                            </Link>
                            <a href='https://alt.cause.id/blogs' onClick={()=>{hotjar.event('blog-sidebar-click');}} className={`flex cursor-pointer my-4`}>
                                <div className="navbar-icon icon-blog mx-3"></div>
                                <div className="font-bold">Blog</div>
                            </a>
                            <Link onClick={hideSidebar} to='/move4coffee' className={`${name=='/move4coffee'? 'active' : ''} flex cursor-pointer my-4`}>
                                <div className="navbar-icon icon-coffee mx-3"></div>
                                <div className="font-bold">Move For Coffee</div>
                            </Link>
                            
                            <a href='https://store.cause.id' onClick={()=>{hotjar.event('store-sidebar-click');}} className={`flex cursor-pointer my-4`}>
                                <div className="navbar-icon icon-store mx-3"></div>
                                <div className="font-bold">Store</div>
                            </a>
                            {/* <Link to='/blogs' className={`${name=='/blogs'? 'active' : ''} flex cursor-pointer my-4`}>
                                <div className="navbar-icon icon-blog mx-3"></div>
                                <div className="font-bold">Blog</div>
                            </Link> */}
                            
                            
                            <Link to='/contactus' className={`${name=='/contactus'? 'active' : ''} flex md:hidden cursor-pointer my-4`}>
                                <div className="navbar-icon icon-contact mx-3"></div>
                                <div className="font-bold">Contact Us</div>
                            </Link>
                            {isLoggedIn ? (
                            <>
                                {/* <Link to='/settings#ConnectApps' className={`${name=='/settings#ConnectApps'? 'active' : ''} flex cursor-pointer my-4`}>
                                <div className="navbar-icon icon-sync mx-3"></div>
                                <div className="font-bold">Connect Apps</div>
                                </Link> */}
                                
                                {/* <div onClick={logOut} className={`${name=='/settings#LogOut'? 'active' : ''} flex cursor-pointer my-4`}>
                                    <div className="navbar-icon icon-logout mx-3"></div>
                                    <div className="font-bold">Log Out</div>
                                </div> */}
                            </>

                            ) : (
                                <></>
                            )}
                            <Link to='/faq' className={`${name=='/faq'? 'active' : ''} flex cursor-pointer my-4`}>
                                <div className="navbar-icon icon-faq mx-3"></div>
                                <div className="font-bold">Faq</div>
                            </Link>
                            
                            
                    


                        </div>
                    </div>
                    <div className='flex justify-center pb-28'>
						<label id='switchToggle' className="switch-toggle"
                        style={darkmodeActive ? {backgroundColor:`rgb(197, 36, 52)`}:{backgroundColor:`grey`}}
                        >
                            <ThemeContext.Consumer>
                                {({ changeTheme }) => (
                                <input 
                                checked={darkmodeActive} 
                                onClick={()=>{
                                // window.location.reload();
                                setDarkmodeActive(!darkmodeActive);
                                changeTheme(darkmodeActive ? themes.light : themes.dark)
                                }} 
                                onChange={()=>{return ''}}
                                id={'darkModeCheckBox'} 
                                type="checkbox"/>
                                )}
                            </ThemeContext.Consumer>							
                        <span className="slider-toggle round"></span>
						</label>
						<b className='darkmode-label'>Dark Mode</b>
					</div>
                    
                </div>
            </div>
        </>
    )
}
$('#overlay').hide()
function showSidebar(){
    $('#sidebar').removeClass('sidebar-hidden');
    $('#overlay').show()
};
function hideSidebar(){
    $('#sidebar').addClass('sidebar-hidden');
    $('#overlay').hide();
}

export default Header