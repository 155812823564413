import { Routes, Route, Link } from "react-router-dom";
import appstore from "../Assets/Images/appstore.png";
import playstore from "../Assets/Images/playstore.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

import logo from "../Assets/Images/logo.png"

const Footer = () => {
    return(
        <>
            <div className="flex pt-7">
                <div className="grid grid-cols-4 w-full px-5 lg:px-[100px] 2xl:px-[100px] text-white"> 
                    <div className="hidden md:block col-span-4 mb-5">
                    <img alt="" className="lighten w-[200px]" src={logo}></img>
                    </div>
                    <div className="hidden md:block col-span-2 pr-5">
                        <div className="font-semibold mb-2">PT Sehat Dengan Olahraga</div>
                        <div className="flex">
                            <FontAwesomeIcon className="" icon={faMapMarkerAlt}></FontAwesomeIcon>
                            <div className="pl-5 text-[13px] font-light">
                                <p> Centennial tower, Lt. 29, Kav. 24-25 Unit D-E</p>
                                <p>Jl. Jenderal Gatot Subroto No. 27, Jakarta 12950</p>
                            </div>
                        </div>
                        <div className="footer-header text-xl font-semibold text-white mb-2 mt-5">Follow Us</div>
                        <div className="flex w-full justify-start h-24">
                            <div className="mx-3 text-white cursor-pointer"
                            onClick={()=>window.open('https://instagram.com/causeid')}
                            >
                            <FontAwesomeIcon 
                                icon={["fab", "instagram"]}
                                size="2x"
                            />
                            </div>
                            <div className="mx-3 text-white cursor-pointer"
                            onClick={()=>window.open('https://wa.me/6287730476840')}
                            >
                            <FontAwesomeIcon 
                                icon={["fab", "whatsapp"]}
                                size="2x"
                            />
                            </div>
                            <div className="mx-3 text-white cursor-pointer"
                            onClick={()=>window.open('https://www.tiktok.com/@causeid')}
                            >
                            <FontAwesomeIcon 
                                icon={["fab", "tiktok"]}
                                size="2x"
                            />
                            </div>
                        </div>
                    </div>
                    <div className="hidden md:block">
                        <div className="footer-header text-xl font-semibold text-white mb-2">LINKS</div>
                        <div className="footer-item font-light">
                            <Link to='/race'>
                            <div>Upcoming Events</div>
                            </Link>
                            <a href='https://alt.cause.id/blogs'>
                            <div>Blogs & Articles</div>
                            </a>
                            <Link to='/faq'>
                            <div>Frequently Asked Questions</div>
                            </Link>
                            <Link to='/aboutus'>
                            <div>About Us</div>
                            </Link>
                            <Link to='/contactus'>
                            <div>Contact Us</div>
                            </Link>
                        </div>
                    </div>
                    <div className="col-span-4 md:col-span-1 mb-5">
                        <div className="footer-header text-sm md:text-xl font-semibold text-white mb-2">Download Now</div>
                        <div className="flex md:block justify-left">
                        <img onClick={()=>window.open('https://apps.apple.com/id/app/cause-virtual-run/id1479524333')} className="border rounded-md w-[125px] md:w-auto cursor-pointer mr-2 md:mr-0" src={appstore}></img>
                        <img onClick={()=>window.open('https://play.google.com/store/apps/details?id=id.cause')} className="md:my-2 w-[125px] md:w-auto cursor-pointer" src={playstore}></img>
                        </div>
                    </div>
                    <div className="md:hidden col-span-4 md:col-span-1 mb-5">
                        <div className="footer-header text-sm md:text-xl font-semibold text-white mb-2">Follow Us</div>
                            <div className="flex w-full justify-start">
                            <div className="mx-3 text-white cursor-pointer"
                            onClick={()=>window.open('https://instagram.com/causeid')}
                            >
                            <FontAwesomeIcon 
                                icon={["fab", "instagram"]}
                                size="2x"
                            />
                            </div>
                            <div className="mx-3 text-white cursor-pointer"
                            onClick={()=>window.open('https://wa.me/6287730476840')}
                            >
                            <FontAwesomeIcon 
                                icon={["fab", "whatsapp"]}
                                size="2x"
                            />
                            </div>
                            <div className="mx-3 text-white cursor-pointer"
                            onClick={()=>window.open('https://www.tiktok.com/@causeid')}
                            >
                            <FontAwesomeIcon 
                                icon={["fab", "tiktok"]}
                                size="2x"
                            />
                            </div>
                        </div>
                    </div>

                    <div className="hidden  col-span-4">
                        <img alt="" className="lighten w-[200px]" src={logo}></img>
                    </div>
                    <div className="hidden col-span-4 mb-5">
                        <div className="font-semibold mb-2">PT Sehat Dengan Olahraga</div>
                        <div className="flex">
                            <FontAwesomeIcon className="pt-1" icon={faMapMarkerAlt}></FontAwesomeIcon>
                            <div className="pl-3 font-light ">
                                <p> Centennial tower, Lt. 29, Kav. 24-25 Unit D-E</p>
                                <p>Jl. Jenderal Gatot Subroto No. 27, Jakarta 12950</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-between py-2 px-2 sm:px-10 2xl:px-[400px] text-white font-light text-[13px] md:text-sm text-center">
                <div className="">© Copyright 2021 PT Sehat Dengan Olahraga</div>
                <div className="">
                    <Link to='/privacypolicy'>
                        <span>Privacy Policy</span>
                    </Link>
                    <span className="px-2">|</span>
                    <Link to='/termsandcondition'>
                        <span>Terms and Conditions</span>
                    </Link>
                </div>
            </div>
        </>
    )
}
export default Footer